.col-jsm-step {
  text-align: center;
}

@media screen and (min-width: 720px) {
  .col-jsm-step {
    width: 100%;
  }
  .col-jsm-step-way {
    width: 100%;
  }

  .col-jsm-step-box {
    width: 20%;
  }
  .jsm-long-way {
    border-bottom: 3px solid #212529;
    height: 50%;
    width: 100%;
  }

  .jsm-long-way:after {
    content: "";
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background: #ffca2c;
    left: calc(50% - 15px);
    /*border: 2px solid #fff;*/
    top: -12px;
    display: block;
    position: absolute;
    animation: ripple 2s ease-in-out infinite;
  }

  .stripe-l:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    border-bottom: 3px solid #fff;
    width: calc(100% - 50%);
  }

  .stripe-r:before {
    content: "";
    position: absolute;
    top: 100%;
    right: 0;
    border-bottom: 3px solid #fff;
    width: calc(100% - 50%);
  }
}

@media screen and (max-width: 720px) {
  .col-jsm-step {
    width: 45%;
  }
  .col-jsm-step-way {
    width: 10%;
  }
  .col-jsm-step-box {
    width: 100%;
  }
  .jsm-long-way {
    border-right: 3px solid #212529;
    width: 50%;
    height: 100%;
  }
  .jsm-long-way:after {
    content: "";
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background: #ffca2c;
    top: calc(50% - 15px);
    /*border: 2px solid #fff;*/
    right: -17px;
    display: block;
    position: absolute;
    animation: ripple 2s ease-in-out infinite;
  }

  .stripe-l:before {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    border-right: 3px solid rgb(255, 255, 255);
    height: calc(100% - 50%);
  }

  .stripe-r:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    border-right: 3px solid rgb(255, 255, 255);
    height: calc(100% - 50%);
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 3px rgba(232, 233, 233, 0.5),
      0 0 0 5px rgba(232, 233, 233, 0.3);
  }

  50% {
    box-shadow: 0 0 0 10px rgba(232, 233, 233, 0.5),
      0 0 0 20px rgba(232, 233, 233, 0.3);
  }
  100% {
    box-shadow: 0 0 0 3px rgba(232, 233, 233, 0.5),
      0 0 0 5px rgba(232, 233, 233, 0.3);
  }
}
