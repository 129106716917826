.aimg {
  width: 50px;
  height: 50px;
  padding: 0;
  margin-right: 2.5%;
  margin-left: 2.5%;
  margin-bottom: 5%;
  text-align: center;
}

.phone-table{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.phone-row{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 350px;
  font-size: 13pt;
}

.phone-city{
  width: 135px;
  display: block;
  text-align: end;
  margin-right: 10px;
}

.phone-inn{
  width: 118px;
  display: block;
  text-align: end;
  margin-right: 10px;
}

.footer-header{
  margin-bottom: -10px;
}

.INN{
  margin-bottom: 0px;
}

