textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
textarea:active,
input[type="text"]:active,
input[type="password"]:active,
input[type="datetime"]:active,
input[type="datetime-local"]:active,
input[type="date"]:active,
input[type="month"]:active,
input[type="time"]:active,
input[type="week"]:active,
input[type="number"]:active,
input[type="email"]:active,
input[type="url"]:active,
input[type="search"]:active,
input[type="tel"]:active,
input[type="color"]:active,
.uneditable-input:active,
.uneditable-input:focus {
    box-shadow: none;
    outline: 0 none;
}

.logo-link {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.navbar {
    border-bottom: solid;
    border-bottom-width: 1px;
    border-bottom-color: #ffc107;
}

.mr-auto {
    font-size: 1.1em;
}

.logo-img {
    background-image: url("../Media/Kawaii_Logo_Short.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 208px;
    height: 65px;
}

.buy-car {
    /* Важные стили для анимации */
    position: relative;
    overflow: hidden;
}

.buy-car:after {
    content: "";
    display: block;
    width: 20px;
    height: 200px;
    margin-left: 50px;
    background: #fff;
    background: linear-gradient(
            to bottom,
            rgba(255, 193, 7, 0.5) 0%,
            rgba(255, 193, 7, 1) 50%,
            rgba(255, 193, 7, 0.5) 100%
    );
    left: -40px;
    top: -100px;
    z-index: 1;
    transform: rotate(45deg);
    position: absolute;
    animation: movingFlare 10s ease-in-out 0.05s infinite;
}

@keyframes movingFlare {
    0% {
        left: -30px;
        margin-left: 0px;
    }
    10% {
        left: 110%;
        margin-left: 80px;
    }
    20% {
        left: 110%;
        margin-left: 80px;
    }
    100% {
        left: 110%;
        margin-left: 80px;
    }
}
.header-feedback{
    display: flex;
}
.aimg {
    width: 50px;
    height: 50px;
    padding: 0;
    margin-right: 2.5%;
    margin-left: 2.5%;
    margin-bottom: 5%;
    text-align: center;
}
/*@media (max-width: 649px) {*/
/*    .logo-img {*/
/*        background-image: url("../Media/Kawaii_Logo_Short.png");*/
/*        background-repeat: no-repeat;*/
/*        background-size: contain;*/
/*        width: 75px;*/
/*        height: 49px;*/
/*    }*/
/*}*/