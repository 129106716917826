.ImageOverlay {
    height: 50px;
}

.Image {
    width: 100px;
    height: 100px;
    position: absolute;
    top: -50px;
}

.car-card{
    padding: 0px 10px 0px 10px;
}

.car-photo{
    width: 100%;
}

.car-table{
    display: flex;
    flex-direction: column;
}

.car-table-col1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.car-row{
    display: flex;
    flex-direction: row;
}

.car-header{
    padding-right: 3px;
}

.car-table-col2{
    height: 65px;
}