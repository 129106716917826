@keyframes destraSinistra {
  0% {
    left: 0%;
    transform: scale(1, 1);
  }
  1% {
    transform: scale(-1, 1);
  }
  49% {
    left: 100%;

    transform: scale(-1, 1);
  }
  50% {
    transform: scale(1, 1);
  }

  100% {
    left: 0%;
    transform: translateX(0%);
    transform: scale(1, 1);
  }
}

#ship {
  position: absolute;
  animation: destraSinistra 20s ease-in-out infinite;
}

#shipdiv {
  position: relative;
}

.line {
  background-image: linear-gradient(
    to right,
    black 33%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 7px 2px;
  background-repeat: repeat-x;
  height: 5px;
  width: 100%;
}

.about-us{
  padding-top: 125px;
}
