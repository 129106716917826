textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
textarea:active,
input[type="text"]:active,
input[type="password"]:active,
input[type="datetime"]:active,
input[type="datetime-local"]:active,
input[type="date"]:active,
input[type="month"]:active,
input[type="time"]:active,
input[type="week"]:active,
input[type="number"]:active,
input[type="email"]:active,
input[type="url"]:active,
input[type="search"]:active,
input[type="tel"]:active,
input[type="color"]:active,
.uneditable-input:active,
.uneditable-input:focus {
    border-color: none;
    box-shadow: none;
    outline: 0 none;
}

@media screen and (min-width: 720px) {
    .adaptivform {
        width: 70%;
    }
}

@media screen and (max-width: 720px) {
    .adaptivform {
        width: 100%;
    }
}

.fonimgsend {
    background-image: url("../Media/Send/fon.png");
    background-repeat: no-repeat; /* Отменяем повторение фона */
    background-position: 50% 50%;
    background-size: cover;
}

.bg-white-al {
    background-color: rgba(255, 255, 255, 0.9);
}

.messageBox {
    rows: 3;
}

.transparent {
    background: rgba(255, 255, 255, .7);
}

.non-transparent {
    opacity: 1 !important;
}