.box-show-l {
  position: relative;
  animation: showLeft 1s;
}

.box-show-r {
  position: relative;
  animation: showRight 1s;
}

@keyframes showLeft {
  from {
    left: -100%;
    opacity: 0;
  }
  to {
    left: 0%;
    opacity: 1;
  }
}

@keyframes showRight {
  from {
    left: 0%;
    opacity: 1;
  }
  to {
    left: -100%;
    opacity: 0;
  }
}

.img-adap {
  width: 100%;
  height: auto;
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.react-multiple-carousel__arrow:hover {
  background-color: rgba(248, 249, 250, 0.8);
}

.react-multiple-carousel__arrow {
  background-color: rgba(248, 249, 250, 0.3);
  color: black;
}

.react-multiple-carousel__arrow::before {
  color: black;
}

.comment-form{
  margin-bottom: 20px;
}