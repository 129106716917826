.nav-link:active,
.nav-link:hover,
.nav-link {
    border: none;
    outline: none;
}

p {
    font-size: 13pt;
}

.fade-black {
    background-color: rgba(33, 37, 41, 0.8);
}

.abs-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

button:active,
button:focus {
    outline: none;
}

.not-radius {
    border-radius: 2px;
}

.fonimg {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.imgback {
    position: absolute;
    z-index: -100;
}

.a-s-none {
    color: rgb(255, 255, 255) !important;
    text-decoration: none;
}

.fonimg-2 {
    background-image: url("./Media/C-HR_003.jpg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
}

.row {
    display: flex;
    justify-content: center;
}

@font-face {
    font-family: 'Blogger';
    src: local('Blogger'), url("./Media/fonts/Blogger Sans.otf") format('truetype');
}

body {
    font-family: 'Blogger' !important;
}