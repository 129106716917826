.CounterUp {
  text-align: center;
  color: #212529;
  font-weight: normal;
}

.Block {
  text-align: center;
}

.hone {
  font-weight: normal;
}

@media screen and (min-width: 720px) {
  .Pad {
    width: 65%;
  }
  .yPad,
  .ymoblbg {
    background-color: white;
  }
}

@media screen and (max-width: 720px) {
  .Pad {
    width: 100%;
  }
  .yPad {
    background-color: #f8f9fa;
    padding: 1rem;
  }
  .ymoblbg {
    background-color: #ffc107;
  }
}
