.ImageOverlay {
  height: 50px;
}

.Image {
  width: 100px;
  height: 100px;
  position: absolute;
  top: -50px;
}

.comment{
  font-style: italic;
}

.comment-div{
  height: 30px;
}